<template>
  <b-container class="page-container">
    <Breadcrumbs currentpage="Inloggen" />
    <h1>Inloggen</h1>
    <p>
      Wil je weten of je in aanmerking komt, lees dan zeker ons
      <a href="https://www.vlaamsbrabant.be/nl/uitleendienst-reglement"
        >reglement</a
      >.
    </p>
    <p>
      Opgelet: vanaf 1 juli 2020 zijn we overgeschakeld op een nieuw
      uitleenprogramma (alsook een vernieuwd reglement), elke vereniging dient
      zich bijgevolg opnieuw te registreren. Log-in gegevens verkregen voor 1
      juli 2020 zijn niet meer bruikbaar.
    </p>
    <b-alert v-if="inactiveAccount" show variant="warning"
      ><font-awesome-icon icon="exclamation-circle" /> Je emailadres is nog niet
      bevestigd. Klik op de link om je emailadres te bevestigen in de door ons
      verzonden mail, deze heb je na het aanmaken van je account ontvangen.
    </b-alert>
    <b-alert v-if="error" show variant="warning"
      ><font-awesome-icon icon="exclamation-circle" /> {{ error }}</b-alert
    >
    <b-alert v-if="passwordReset" show variant="warning"
      ><font-awesome-icon icon="exclamation-circle" /> Je wachtwoord is
      successvol aangepast.
    </b-alert>
    <b-alert v-if="userLockedOut" show variant="warning"
      ><font-awesome-icon icon="exclamation-circle" /> Je account is geblokkeerd
      wegens teveel foutieve inlogpogingen, klik op wachtwoord vergeten om je
      wachtwoord te resetten en je account te deblokkeren.
    </b-alert>
    <b-alert v-if="invalidPassword" show variant="warning"
      ><font-awesome-icon icon="exclamation-circle" /> Je gebruikersnaam en
      wachtwoord combinatie is onjuist.
    </b-alert>
    <b-row>
      <b-col sm="12" class="mb-3"
        ><h3>Vul je accountgegevens in om in te loggen</h3></b-col
      >

      <b-col sm="12" md="12" lg="6" xl="4">
        <b-card>
          <b-form @submit.prevent="loginFormSubmit">
            <b-form-group
              id="input-group-1"
              label="E-mailadres"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="form.username"
                type="text"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Wachtwoord"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                v-model="form.password"
                required
                type="password"
              ></b-form-input>
            </b-form-group>
            <b-row>
              <b-col cols="12">
                <b-button class="mb-2" type="submit" variant="primary"
                  >Inloggen
                  <font-awesome-icon
                    v-if="loggingIn"
                    class="fa-spin"
                    far
                    icon="spinner"
                /></b-button>
              </b-col>
              <b-col cols="12">
                <router-link :to="{ name: 'forgotPassword' }"
                  >Wachtwoord vergeten?</router-link
                >
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
      <b-col sm="12" md="8" class="pt-4 pt-xl-0">
        <h4>Nog geen logingegevens?</h4>
        <p>
          Je moet je aanmelden alvorens je materiaal kan aanvragen. Vraag daarom
          een login aan.
        </p>
        <router-link class="btn btn-outline-primary" :to="{ name: 'register' }"
          >Account aanvragen</router-link
        >
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { login } from '@/services/AuthorizationService'
import Breadcrumbs from '@/components/header/BreadCrumbs'
export default {
  components: { Breadcrumbs },
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      invalidPassword: false,
      inactiveAccount: false,
      userLockedOut: false,
      loggingIn: false,
      error: null,
      visibleStatuses: ['3. Actief', '4. Tijdelijk account']
    }
  },
  computed: {
    passwordReset: function() {
      return this.$route.query.passwordReset
    }
  },
  metaInfo: function() {
    return {
      title: `Inloggen`,
      titleTemplate: '%s - Vlaams-Brabant Uitleendienst'
    }
  },
  methods: {
    async loginFormSubmit() {
      this.loggingIn = true
      this.invalidPassword = false
      this.inactiveAccount = false
      this.userLockedOut = false
      this.error = null

      const result = await login({
        username: this.form.username,
        password: this.form.password
      })

      // Warnings based on priority
      if (result === 'Organisation inactive') {
        this.error =
          'Je vereniging is tijdelijk geschorst. Neem contact op met de uitleendienst voor meer informatie. '
        this.loggingIn = false
        return
      }

      if (result === 'newOrganisation') {
        this.error =
          'Je vereniging werd recent aangemeld en is nog niet behandeld door de uitleendienst dit kan enkele dagen duren.'
        this.loggingIn = false
        return
      }

      if (result === 'processingOrganisation') {
        this.error =
          'Je vereniging werd recent aangemeld, de uitleendienst heeft bijkomende informatie opgevraagd en onderzoekt of je vereniging in aanmerking komt om materiaal te ontlenen.'
        this.loggingIn = false
        return
      }

      if (result === 'invalidatedOrganisation') {
        this.error =
          'Je vereniging komt niet in aanmerking om materiaal te mogen ontlenen bij de provinciale uitleendienst.'
        this.loggingIn = false
        return
      }

      if (result.data != null) {
        if (result.data.Message == 'IncorrectDetails') {
          this.invalidPassword = true
        } else if (result.data.Message == 'UserNotApproved') {
          this.inactiveAccount = true
        } else if (result.data.Message == 'UserLockedOut') {
          this.userLockedOut = true
        } else if (
          result.data.Accounts &&
          result.data.Accounts.Collection.filter(account =>
            this.visibleStatuses.includes(account.CUST_Status)
          ).length > 1
        ) {
          this.$router.push({ name: 'profileSelector' })
        } else {
          this.$router.push({ name: 'index', params: { loggedIn: true } })
        }
      } else {
        this.inactiveAccount = true
      }

      this.loggingIn = false
    }
  }
}
</script>
<style scoped lang="scss">
a.btn {
  text-decoration: none;
}
</style>
